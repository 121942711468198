import Modal from "../modal"
import stripeData from "../src/payment-stripe-data"

const getStripeInfo = (item) => {
  const isLocal = !window.location.host.includes('speakflow.com')
  const env = isLocal ? 'local' : 'prod'
  return stripeData[env][item]
}

const initPayment = function (intervalOverride) {
  Modal.init()

  window.isTestEnvironment = JSON.parse(document.querySelector('body').dataset.test)

  const planInfo = {
    basic: {
      monthly: {
        price: '$10',
        interval: '/month',
        priceId: getStripeInfo('basic_monthly'),
        savings: 'billed monthly'
      },
      yearly: {
        price: '$8.25',
        interval: '/month',
        priceId: getStripeInfo('basic_yearly'),
        savings: 'billed once yearly'
      },
    },
    plus: {
      monthly: {
        price: '$19',
        interval: '/month',
        priceId: getStripeInfo('plus_monthly'),
        savings: 'billed monthly'
      },
      yearly: {
        price: '$15',
        interval: '/month',
        priceId: getStripeInfo('plus_yearly'),
        savings: 'billed once yearly'
      }
    },
    pro: {
      monthly: {
        price: '$40',
        interval: '/month',
        priceId: getStripeInfo('pro_monthly'),
        savings: 'billed monthly'
      },
      yearly: {
        price: '$30',
        interval: '/month',
        priceId: getStripeInfo('pro_yearly'),
        savings: 'billed once yearly'
      }
    },
    business: {
      monthly: {
        price: '$149',
        interval: '/month',
        priceId: getStripeInfo('business_monthly'),
        savings: 'billed monthly'
      },
      yearly: {
        price: '$129',
        interval: '/month',
        priceId: getStripeInfo('business_yearly'),
        savings: 'billed once yearly'
      }
    }
  }

  const setPlanInfo = (interval) => {
    const selectedInterval = intervalOverride || interval

    Object.keys(planInfo).forEach((plan) => {
      let planWrap = document.querySelector(`[data-plan="${plan}"]`)
      if (!planWrap) return;
      const subAmount = planWrap.querySelector('#sub-amount')
      const planSavings = planWrap.querySelector('#sub-savings')
      const planInterval = planWrap.querySelector('#sub-interval')

      if (subAmount) subAmount.innerHTML = planInfo[plan][selectedInterval].price
      if (planSavings) planSavings.innerHTML = planInfo[plan][selectedInterval].savings
      if (planInterval) planInterval.innerHTML = planInfo[plan][selectedInterval].interval

      const planBtn = planWrap.querySelector('.js-cta-btn')
      if (planBtn) {
        planBtn.dataset.priceId = planInfo[plan][selectedInterval].priceId
      }
    })
  }

  setPlanInfo('yearly')

  // set basic plan info
  const basicBtn = document.querySelector('.js-basic-plan-btn')
  if (basicBtn) {
    basicBtn.dataset.priceId = getStripeInfo('basic_yearly')
  }

  document.querySelectorAll('.price-interval-toggle').forEach((btn) => {
    btn.addEventListener('click', (e) => {
      const plan = e.currentTarget.dataset.plan
      setPlanInfo(plan)
      document.querySelector('.price-interval-toggle.active')?.classList?.remove('active')
      e.currentTarget.classList.add('active')
    })
  })

  // If a fetch error occurs, log it to the console
  var handleFetchResult = function(result) {
    if (!result.ok) {
      return result.json().then(function(json) {
        if (json.error && json.error.message) {
          throw new Error(result.url + ' ' + result.status + ' ' + json.error.message);
        }
      });
    }
    return result.json();
  };

  // Create a Checkout Session with the selected plan ID
  var createCheckoutSession = function(priceId) {
    return fetch("/create-checkout-session", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        priceId: priceId
      })
    }).then(handleFetchResult);
  };

  // Handle any errors returned from Checkout
  var handleResult = function(result) {
    if (result.error) {
      console.error(result.error.message);
      checkoutBtn.classList.remove('disabled')
    }
  };

  var stripe = Stripe(getStripeInfo('key'));
  // Setup event handler to create a Checkout Session when button is clicked
  const checkoutButtons = document.querySelectorAll(".checkout")
  checkoutButtons.forEach((checkoutBtn) => {
    checkoutBtn && checkoutBtn
    .addEventListener("click", function(evt) {

      evt.currentTarget.classList.add('disabled')

      const plan = evt.currentTarget.dataset.plan
      const priceId = evt.currentTarget.dataset.priceId

      evt.preventDefault()

      if (isTestEnvironment) {
        return
      }

      createCheckoutSession(priceId).then(function(data) {
        // Call Stripe.js method to redirect to the new Checkout page
        if (data.sessionId) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId,
            })
            .then(handleResult);
        } else {
          console.log(data)
        }
      }).catch((error) => {
        Logger('Payment', error)
      })
    });
  })

  // triggger basic modal via url
  if (window.location.href.includes('#basic')) {
    setTimeout(function () {
      Modal.show('modal-basic-plan')
    }, 0)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (!document.querySelector('#pricing-plans')) return;
  initPayment()
})

export default initPayment;
