const stripeData = {
  local: {
    key: 'pk_test_6eGYUm7XQxvuZl2VEFpYQ4Dq',

    basic_monthly: 'price_1PT8rRAbGm3tLW8vqLfjU8sl', // $10/mo
    basic_yearly: 'price_1OSUDlAbGm3tLW8v13k7AyAX', // $99/year

    plus_monthly: 'price_1J9RXpAbGm3tLW8vFStHbqU8', // $12
    plus_yearly: 'price_1NAntkAbGm3tLW8v5lVHpT1F', // $120

    pro_monthly: 'price_1N4uNcAbGm3tLW8vaAAO4IqL', // $40/mo
    pro_yearly: 'price_1N4uNcAbGm3tLW8vDDZ7D4wl',  // $360/year

    business_monthly: 'price_1JXbc7AbGm3tLW8vq2TXNlaU',
    business_yearly: 'price_1JXbc7AbGm3tLW8vhmJFJ6Bf'
  },
  prod: {
    key: 'pk_live_KoBTXjSbj6cUIhYzkKNLGbor',

    basic_monthly: 'price_1PSiwzAbGm3tLW8vILnPrnTn', // $10/mo
    basic_yearly: 'price_1OSTccAbGm3tLW8v9McmpjDu', // $99/year

    plus_monthly: 'price_1NlMr7AbGm3tLW8v2KAMeyxu', // $19/mo
    plus_yearly: 'price_1No8OUAbGm3tLW8vjoYFSxfE', // $180/year - $19/year

    pro_monthly: 'price_1N4uK3AbGm3tLW8vy8liR0f7', // $40/mo
    pro_yearly: 'price_1N4uJzAbGm3tLW8vLlLdakFf', // $360/year

    business_monthly: 'price_1JYvC2AbGm3tLW8v6VzBecpy',
    business_yearly: 'price_1JYvCTAbGm3tLW8vLNMSaVHb'
  }
}

export default stripeData;
